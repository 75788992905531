import { useRef, useState, useEffect, useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  Menu,
  LoadingOverlay,
  Drawer,
  Divider,
  ActionIcon,
  Tooltip,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import dayjs from 'dayjs'
import { ApplicantStageFilter } from 'types/graphql'

import { Metadata, useMutation } from '@redwoodjs/web'
import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import { DatePickerFilter } from 'src/components/Inputs/DatePickerFilter/DatePickerFilter'
import { SearchBox } from 'src/components/Inputs/SearchBox/SearchBox'
import PaginationArea from 'src/components/Navigation/PaginationArea/PaginationArea'
import ActionsMenu from 'src/components/Overlays/ActionsMenu/ActionsMenu'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  usePhoneDevice,
  usePhoneDeviceDispatch,
} from 'src/context/PhoneDeviceContext'
import {
  DOWNLOAD_CSV,
  GET_APPLICANTS,
  GET_APPLICANT_METRICS,
} from 'src/graphql/applicants.graphql'
import { GET_EMPLOYEES } from 'src/graphql/employees.graphql'
import { GET_JOB_LISTINGS } from 'src/graphql/joblistings.graphql'
import { ApplicantStage } from 'src/graphql/types/applicants'
import { StepStatus } from 'src/graphql/types/steps'
import { usePage } from 'src/hooks/usePage/usePage'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconClose from 'src/icons/IconClose'
import IconFilter from 'src/icons/IconFilter'
import { formatSnakeValue } from 'src/lib/formatters'
import { PhoneDeviceActionType } from 'src/lib/phone-device.reducer'
import { base64ToBlob, downloadFileBlob } from 'src/lib/string-util'
import MetricList from 'src/pages/ApplicantsPage/components/MetricList/MetricList'
import {
  SelectedFilter,
  SelectedFiltersBar,
} from 'src/pages/ApplicantsPage/components/SelectedFiltersBar/SelectedFiltersBar'

import ApplicantSidebar from '../ApplicantDetailsPage/sidebar/ApplicantSidebar'

import ApplicantsTable from './components/ApplicantsTable/ApplicantsTable'
import BulkCallDrawer from './components/BulkActionsDrawers/BulkCallDrawer'
import BulkSmsDrawer from './components/BulkActionsDrawers/BulkSmsDrawer'
import BulkStatusDrawer from './components/BulkActionsDrawers/BulkStatusDrawer'
import CreateApplicantModal from './components/CreateApplicantModal/CreateApplicantModal'
import {
  MultiSelectFilter,
  RangeFilter,
  DateRangeFilter,
  SearchFilter,
  Filters,
  StepFilters,
} from './components/FiltersSiderbar/Filters'
import FiltersSiderbar from './components/FiltersSiderbar/FiltersSiderbar'
import { ApplicantFiltersActionType } from './context/applicant-filters.reducer'
import {
  useFilters,
  useFiltersDispatch,
} from './context/ApplicantFiltersContext'
import { loadFiltersFromUrl } from './loadFiltersFromUrl'
import { updateFiltersInUrl } from './updateFiltersInUrl'

const DATE_FORMAT = 'MM/DD/YYYY'
const LIMIT_CSV = 500

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

interface FilterParams {
  applicantStages: string[]
  searchText: string
  applicationDate: { gte: Date | null; lte: Date | null }
  recruiters: string[]
  jobListings: string[]
}

const ApplicantsPage = () => {
  const [opened, { open, close }] = useDisclosure(false)
  const buttonRef = useRef(null)
  const [page, setPage] = usePage()
  const [resultsPerPage, setResultsPerPage] = useState(10)
  const [drawerOpened, drawerHandlers] = useDisclosure(false)
  const [bulkSmsDrawerOpened, bulkSmsDrawerHandlers] = useDisclosure(false)
  const [bulkCallDrawerOpened, bulkCallDrawerHandlers] = useDisclosure(false)
  const [bulkStatusDrawerOpened, bulkStatusDrawerHandlers] =
    useDisclosure(false)
  const [selectedApplicant, setSelectedApplicant] = useState<string>(null)
  const [selectedApplicants, setSelectedApplicants] = useState<string[]>([])
  const [openFilters, { open: setOpenFilters, close: closeFilters }] =
    useDisclosure(false)

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([])

  const [selectedKPI, setSelectedKPI] = useState()

  const dispatchFilters = useFiltersDispatch()

  const [filtersLoadedOnPageLoad, setFiltersLoadedOnPageLoad] = useState(false)

  const dispatchPhoneAction = usePhoneDeviceDispatch()

  const phoneDeviceContext = usePhoneDevice()

  const [loadJobListings] = useLazyQuery(GET_JOB_LISTINGS, {
    variables: {
      pageInfo: { offset: 0, limit: 20 },
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const [loadRecruiters] = useLazyQuery(GET_EMPLOYEES, {
    variables: {
      filters: { roleTypes: 'RECRUITER' },
      pageInfo: { offset: 0, limit: 20 },
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const init = useCallback(async () => {
    const [{ data: jobListings }, { data: recruiters }] = await Promise.all([
      loadJobListings(),
      loadRecruiters(),
    ])

    const urlFilters = loadFiltersFromUrl({
      jobListings: jobListings?.jobListings?.items ?? [],
      recruiters: recruiters?.employees?.items ?? [],
    })
    searchTextSet(urlFilters.searchText.searchText)

    dispatchFilters({
      type: ApplicantFiltersActionType.FILTERS_CHANGED,
      payload: urlFilters,
    })
    setFiltersLoadedOnPageLoad(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: applicantMetrics, loading: loadingMetrics } = useQuery(
    GET_APPLICANT_METRICS,
    {
      variables: {
        groupBy: 'hiringStage',
        filters: {
          applicantStages: {
            OR: [
              {
                applicantStage: ApplicantStage.PROSPECT,
              },
            ],
          },
        },
      },
      onError,
    }
  )

  const filters = useFilters()
  const [searchText, searchTextSet] = useState('')

  const [loadApplicants, { data: applicantsPage, loading }] = useLazyQuery(
    GET_APPLICANTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        pageInfo: {
          offset: (page - 1) * resultsPerPage,
          limit: resultsPerPage,
        },
        filters: buildFiltersGraphqlParam(filters),
        stepFilters: {
          OR: [
            { status: StepStatus.IN_PROGRESS },
            { status: StepStatus.PENDING_REVIEW },
          ],
        },
      },
      onError: (error) => {
        console.log('error', error)
        toast('Something went wrong, please try again.', 'error')
      },
    }
  )

  const [downloadCSV] = useMutation(DOWNLOAD_CSV, {
    variables: {
      filters: buildFiltersGraphqlParam(filters),
      pageInfo: { offset: 0, limit: LIMIT_CSV },
    },
    onCompleted: (result) => {
      if (result?.downloadCSV?.fileContent) {
        const fileAsBinary = base64ToBlob({
          base64String: result.downloadCSV.fileContent,
          contentType: 'text/csv',
        })
        downloadFileBlob({
          file: fileAsBinary,
          fileName: result.downloadCSV.fileName,
        })
      }
    },
    onError: (err) => {
      console.log(err)
      toast('Sorry, we cannot download the CSV file', 'error')
    },
  })

  useEffect(() => {
    if (filtersLoadedOnPageLoad) {
      loadApplicants()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page, resultsPerPage])

  useEffect(() => {
    setPage(1)
  }, [resultsPerPage])

  const onClearFilters = () => {
    searchTextSet('')
    dispatchFilters({
      type: ApplicantFiltersActionType.CLEAR_ALL,
    })
    setSelectedKPI(null)
  }

  useEffect(() => {
    if (filtersLoadedOnPageLoad) {
      setSelectedFilters(buildSelectedFilters(filters))
      setPage(1)
      updateFiltersInUrl(filters)
      setSelectedApplicants([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, filtersLoadedOnPageLoad])

  function buildFiltersGraphqlParam(filters: Filters): FilterParams {
    if (!filters) {
      return
    }
    const filterGraphqlParam: FilterParams = { ...filters }
    const keys = Object.keys(filters)
    for (const key of keys) {
      const filter = filters[key]
      if (filter.type === 'multi-select') {
        filterGraphqlParam[filter.key] =
          (filter as MultiSelectFilter).selectedValues || []
      }
      if (filter.type === 'range' || filter.type === 'date-range') {
        filterGraphqlParam[filter.key] = {
          lte: (filter as RangeFilter | DateRangeFilter).lte,
          gte: (filter as RangeFilter | DateRangeFilter).gte,
        }
      }
      if (filter.type === 'search') {
        filterGraphqlParam[filter.key] = (filter as SearchFilter).searchText
      }
      if (filter.type === 'step-filter') {
        filterGraphqlParam[filter.key] = {
          OR: (filter as StepFilters).selectedValues || [],
        }
      }
      if (filter.type === 'applicant-stage-filter') {
        filterGraphqlParam[filter.key] = {
          OR: (filter as ApplicantStageFilter).selectedValues || [],
        }
      }
    }
    return filterGraphqlParam
  }

  function buildSelectedFilters(selectedValuesByFilter): SelectedFilter[] {
    const selectedFilters: SelectedFilter[] = []
    for (const key in selectedValuesByFilter) {
      const filter = selectedValuesByFilter[key]

      if (
        filter?.type === 'multi-select' &&
        filter?.selectedValues?.length > 0
      ) {
        filter.selectedValues.forEach((selectedValue) => {
          const option = filter.options.find(
            (option) => option.value === selectedValue
          )
          if (option) {
            selectedFilters.push({
              key: filter.key,
              label: option.label,
              value: selectedValue,
            })
          }
        })
      } else if (filter?.type === 'range' && (filter?.gte || filter?.lte)) {
        const existsBothAndAreDifferent =
          typeof filter.gte !== 'undefined' &&
          typeof filter.lte !== 'undefined' &&
          filter.gte != filter.lte

        const label = existsBothAndAreDifferent
          ? `${filter.gte ?? filter.min} - ${filter.lte || filter.max}`
          : `${filter.lte || filter.gte}`

        const areDifferentFromLimits =
          filter.gte !== filter.min || filter.lte !== filter.max

        if (areDifferentFromLimits) {
          if (filter.key === 'yearsOfExperience') {
            selectedFilters.push({
              key: filter.key,
              label: `experience: ${label} years`,
              value: filter.selectedValues,
            })
          }
          if (filter.key === 'callCount') {
            selectedFilters.push({
              key: filter.key,
              label: `Call count: ${label}`,
              value: filter.selectedValues,
            })
          }
        }
      } else if (filter?.type === 'search' && filter.searchText) {
        selectedFilters.push({
          key: filter.key,
          label: `search: ${filter.searchText}`,
          value: filter.searchText,
        })
      } else if (
        filter?.type === 'date-range' &&
        (filter?.gte || filter?.lte)
      ) {
        const existsBothAndAreDifferent =
          typeof filter.gte &&
          typeof filter.lte &&
          filter.gte &&
          filter.lte &&
          filter.gte != filter.lte

        const label = existsBothAndAreDifferent
          ? `${dayjs(filter.gte).format(DATE_FORMAT)} - ${dayjs(
              filter.lte
            ).format(DATE_FORMAT)}`
          : `${
              dayjs(filter.gte).format(DATE_FORMAT) ||
              dayjs(filter.lte).format(DATE_FORMAT)
            }`

        selectedFilters.push({
          key: filter.key,
          label,
          value: filter.selectedValues,
        })
      } else if (
        filter?.type === 'step-filter' &&
        filter?.selectedValues?.length > 0
      ) {
        filter.selectedValues.forEach((selectedValue) => {
          const option = filter.options.find(
            (option) => option.value === selectedValue.type
          )
          if (option) {
            // Check if status exists and is not undefined
            const statusPart = selectedValue.status
              ? `: ${formatSnakeValue(selectedValue.status)}`
              : ''

            selectedFilters.push({
              key: `${filter.key}-${selectedValue.type}-${selectedValue.status}`,
              label: `${option.label}${statusPart}`,
              value: selectedValue,
            })
          }
        })
      } else if (
        filter?.type === 'applicant-stage-filter' &&
        filter?.selectedValues?.length > 0
      ) {
        filter.selectedValues.forEach((selectedValue) => {
          const option = filter.options.find(
            (option) => option.value === selectedValue.applicantStage
          )
          if (option) {
            // Check if status exists and is not undefined
            const subOption =
              selectedValue.hiringStage ||
              selectedValue.terminatedReason ||
              selectedValue.disqualifiedReason ||
              selectedValue.notInterestedReason
            const statusPart = subOption
              ? `: ${formatSnakeValue(subOption)}`
              : ''

            selectedFilters.push({
              key: `${filter.key}-${selectedValue.applicantStage}-${subOption}`,
              label: `${option.label}${statusPart}`,
              value: selectedValue,
            })
          }
        })
      }
    }
    return selectedFilters
  }

  const onSearch = (text: string) => {
    dispatchFilters({
      type: ApplicantFiltersActionType.SEARCHBOX_CHANGED,
      payload: {
        text,
      },
    })
  }
  const onDateRangeChange = (dateRange: { from: Date; to: Date }) => {
    dispatchFilters({
      type: ApplicantFiltersActionType.APPLICANTION_DATE_CHANGED,
      payload: {
        from: dateRange.from,
        to: dateRange.to,
      },
    })
  }
  const onRemoveSelectedFilter = (filter: SelectedFilter) => {
    const multiSelectFilters = {
      recruiters: 1,
      jobListings: 1,
    }
    const rangeFilters = {
      applicationDate: 1,
      yearsOfExperience: 1,
      callCount: 1,
    }

    if (multiSelectFilters[filter.key]) {
      dispatchFilters({
        type: ApplicantFiltersActionType.CLEAN_MULTI_SELECT,
        payload: filter,
      })
    }

    if (
      multiSelectFilters[filter.key] &&
      filter.key === 'hiringStages' &&
      selectedKPI
    ) {
      setSelectedKPI(null)
    }

    if (filter.key === 'searchText') {
      searchTextSet('')
      dispatchFilters({
        type: ApplicantFiltersActionType.CLEAN_SEARCHBOX,
      })
    }
    if (rangeFilters[filter.key]) {
      dispatchFilters({
        type: ApplicantFiltersActionType.CLEAN_RANGE_FILTER,
        payload: {
          filterKey: filter.key,
        },
      })
    }
    if (filter.key.startsWith('steps')) {
      dispatchFilters({
        type: ApplicantFiltersActionType.CLEAN_STEP_FILTER,
        payload: filter,
      })
    }
    if (filter.key.startsWith('applicantStages')) {
      dispatchFilters({
        type: ApplicantFiltersActionType.CLEAR_APPLICANT_STAGE_FILTER,
        payload: filter,
      })
    }
  }

  const handledrawerOpened = (applicantId: string) => {
    setSelectedApplicant(applicantId)
    drawerHandlers.open()
  }

  const onMetricClick = (metric) => {
    setSelectedKPI(metric)
    dispatchFilters({
      type: ApplicantFiltersActionType.FILTER_BY_HIRING_STAGE_METRIC,
      payload: metric,
    })
  }

  const onDownloadCSV = useCallback(() => {
    downloadCSV()
  }, [downloadCSV])

  const onRegisterCall = useCallback(() => {
    loadApplicants()
  }, [])

  const onPhoneCall = useCallback(
    async ({ applicantId, applicantFullName, phone }) => {
      if (phoneDeviceContext?.phoneDevice?.isBusy) {
        return
      }
      dispatchPhoneAction({
        type: PhoneDeviceActionType.CALL,
        payload: {
          applicantId,
          phone,
          applicantFullName,
          // call,
        },
      })
    },
    []
  )

  //This tracks and saves the most recent url with filtered applicants
  //so that it can be used to redirect back to the same page after seeing an applicant's details
  useEffect(() => {
    sessionStorage.setItem(
      'latestUrlWithFilteredApplicants',
      window.location.href
    )
  }, [window.location.href])

  return (
    <div className="flex h-full w-full flex-col gap-3">
      {opened && (
        <CreateApplicantModal isModalOpen={opened} onModalClose={close} />
      )}
      {openFilters && (
        <FiltersSiderbar
          openFilters={openFilters}
          closeFilters={closeFilters}
        />
      )}
      <Metadata title="Applicants" description="Applicants page" />
      {bulkSmsDrawerOpened && (
        <BulkSmsDrawer
          applicants={selectedApplicants}
          setApplicants={setSelectedApplicants}
          isOpen={bulkSmsDrawerOpened}
          onClose={bulkSmsDrawerHandlers.close}
        />
      )}
      {bulkCallDrawerOpened && (
        <BulkCallDrawer
          applicants={selectedApplicants}
          setApplicants={setSelectedApplicants}
          isOpen={bulkCallDrawerOpened}
          onClose={bulkCallDrawerHandlers.close}
          refetchQueries={[GET_APPLICANTS]}
        />
      )}
      {bulkStatusDrawerOpened && (
        <BulkStatusDrawer
          applicants={selectedApplicants}
          setApplicants={setSelectedApplicants}
          isOpen={bulkStatusDrawerOpened}
          onClose={bulkStatusDrawerHandlers.close}
          refetchQueries={[GET_APPLICANTS]}
        />
      )}
      {drawerOpened && (
        <Drawer
          opened={drawerOpened}
          withCloseButton={false}
          onClose={drawerHandlers.close}
          position="right"
          size="22.5vw"
          lockScroll={false}
          overlayProps={{ backgroundOpacity: 0.1 }}
          transitionProps={{
            transition: 'slide-left',
            duration: 150,
            timingFunction: 'linear',
          }}
          classNames={{
            content: 'h-full p-0',
            root: 'p-0 ',
            inner: 'p-0',
          }}
        >
          <div className="h-[95vh]">
            <ApplicantSidebar
              applicantId={selectedApplicant}
              showNavigation={true}
              onRegisterCall={onRegisterCall}
              onPhoneCall={onPhoneCall}
            />
          </div>
        </Drawer>
      )}

      <MetricList
        selectedMetric={selectedKPI}
        metrics={applicantMetrics?.applicantMetrics?.items || []}
        loading={loadingMetrics}
        onMetricClick={onMetricClick}
      />

      <div className="flex h-[86%] flex-col gap-3 px-5 pb-3">
        <div className="flex flex-col gap-2 ">
          <div className="flex flex-1  flex-row ">
            <div className="flex flex-col">
              <div className="text-lg font-semibold text-doubleNickel-gray-900">
                Applicants
              </div>
              <div className="text-sm text-doubleNickel-gray-600">
                {applicantsPage?.applicants?.totalCount || 0} applicants
              </div>
            </div>
            <div className="flex flex-1 flex-row items-center justify-end gap-4">
              <div className="w-[50%]">
                <SearchBox
                  value={searchText}
                  setValue={searchTextSet}
                  placeholder="Press Enter to search by name or phone number"
                  onSearch={onSearch}
                />
              </div>

              <Menu shadow="md" closeOnItemClick={false}>
                <Menu.Target>
                  <Button
                    ref={buttonRef}
                    variant="outline"
                    text="Filter"
                    lefticon={<IconFilter />}
                    onClick={() => setOpenFilters()}
                  />
                </Menu.Target>
              </Menu>
              <DatePickerFilter
                interval={{
                  gte: filters.applicationDate?.gte,
                  lte: filters.applicationDate?.lte,
                }}
                onChange={(dateRange) => onDateRangeChange(dateRange)}
              />

              <ActionsMenu
                text="Actions"
                rightIcon={<IconChevronDown />}
                items={[
                  {
                    label: 'Add Applicant',
                    onClick: () => open(),
                  },
                  {
                    label: 'Download CSV',
                    onClick: onDownloadCSV,
                  },
                  {
                    label: 'Send SMS',
                    hidden: selectedApplicants.length === 0,
                    onClick: () => {
                      bulkSmsDrawerHandlers.open()
                    },
                  },
                  {
                    label: 'Register Call',
                    hidden: selectedApplicants.length === 0,

                    onClick: () => {
                      bulkCallDrawerHandlers.open()
                    },
                  },
                  {
                    label: 'Update Stage',
                    hidden: selectedApplicants.length === 0,

                    onClick: () => {
                      bulkStatusDrawerHandlers.open()
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center gap-4 text-sm font-semibold text-doubleNickel-brand-500">
          {selectedApplicants.length > 0 && (
            <>
              {selectedApplicants.length}{' '}
              {selectedApplicants.length === 1 ? 'applicant' : 'applicants'}{' '}
              selected
              <Tooltip label="Clear all selected applicants">
                <ActionIcon
                  variant="subtle"
                  onClick={() => setSelectedApplicants([])}
                >
                  <IconClose className="fill-none stroke-doubleNickel-brand-500" />
                </ActionIcon>
              </Tooltip>
              <Divider orientation="vertical" />
            </>
          )}
          <SelectedFiltersBar
            selectedFilters={selectedFilters}
            onRemoveFilter={onRemoveSelectedFilter}
            onCleanFilters={onClearFilters}
          />
        </div>

        <ApplicantsTable
          applicantPage={applicantsPage?.applicants}
          selectedApplicants={selectedApplicants}
          setSelectedApplicants={setSelectedApplicants}
          openDrawer={handledrawerOpened}
          loading={loading}
          onPhoneCall={onPhoneCall}
        />
        <LoadingOverlay
          visible={loading}
          overlayProps={{ opacity: 0 }}
          zIndex={1000}
        />

        <PaginationArea
          page={page}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
          totalPages={Math.ceil(
            applicantsPage?.applicants.totalCount / resultsPerPage
          )}
          onPageChange={setPage}
        />
      </div>
    </div>
  )
}

export default ApplicantsPage
