import { useEffect, useState } from 'react'

import { Group, Drawer, Divider, Box, Tooltip } from '@mantine/core'
import { DateTimePicker } from '@mantine/dates'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { BULK_CALL } from 'src/graphql/activityLogs.graphql'
import IconPlane from 'src/icons/IconPlane'

import ApplicantList from './components/ApplicantList'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const BulkCallDrawer = ({
  applicants,
  setApplicants,
  isOpen,
  refetchQueries = [],
  onClose,
}) => {
  const [activityDate, setActivityDate] = useState(new Date())
  const [showingApplicants, setShowingApplicants] = useState(false)
  const [bulkCallResult, setBulkCallResult] = useState(null)

  // MUTATIONS:
  const [bulkCall, { data: bulkCallData, loading: bulkCallLoading }] =
    useMutation(BULK_CALL, {
      refetchQueries: refetchQueries,
      onCompleted: () => {
        toast('Call was registered successfully', 'success')
      },
      onError,
    })

  useEffect(() => {
    if (bulkCallData) {
      setBulkCallResult(bulkCallData.bulkCall.applicants)
    }
  }, [bulkCallData])

  const handleClose = () => {
    setBulkCallResult(null)
    onClose()
  }

  const handleSubmit = () => {
    setShowingApplicants(true)
    bulkCall({
      variables: {
        applicants: applicants.map((applicant) => applicant.applicantId),
        activityDate: activityDate.toISOString(),
      },
    })
  }

  return (
    <Drawer
      opened={isOpen}
      onClose={handleClose}
      closeOnClickOutside={!bulkCallLoading}
      closeOnEscape={!bulkCallLoading}
      title={'Register a Call'}
      classNames={{
        title: 'text-lg font-bold',
        content: 'flex flex-col h-full',
        body: 'flex flex-col justify-between flex-1 px-2 overflow-hidden',
      }}
      position="right"
    >
      <Drawer.Body>
        <div className="flex h-[93%] flex-col gap-5 overflow-auto">
          <div className="text-md flex flex-row items-center gap-4 font-medium text-doubleNickel-gray-900">
            Registering: {applicants.length}{' '}
            {applicants.length === 1 ? 'applicant' : 'applicants'}
            <Box
              className="cursor-pointer text-sm font-semibold text-doubleNickel-brand-500 hover:underline"
              onClick={() => {
                setShowingApplicants(!showingApplicants)
              }}
            >
              {showingApplicants ? 'Hide all' : 'Show all'}
            </Box>
          </div>
          {showingApplicants && (
            <ApplicantList
              applicants={applicants}
              bulkSmsLoading={bulkCallLoading}
              bulkSmsResult={bulkCallResult}
              setApplicants={setApplicants}
            />
          )}
          <Divider />
          <div className="flex flex-col gap-4">
            <div className="text-md font-medium text-doubleNickel-gray-900">
              Select the date and time you want to register:
            </div>

            <DateTimePicker
              label={'Date and Time'}
              value={activityDate}
              onChange={setActivityDate}
              maxDate={new Date()}
              // variant="unstyled"
              valueFormat="MM/DD/YYYY hh:mm A"
              placeholder="Select date and time"
              classNames={{
                label: 'text-sm font-mediun text-doubleNickel-gray-700',
              }}
            />
          </div>
        </div>

        <Group className="absolute bottom-0 left-0 right-0 mt-auto grid grid-cols-2 gap-4 border-t bg-white p-4">
          <Button
            text="Cancel"
            variant="outline"
            onClick={handleClose}
            disabled={bulkCallLoading}
          />
          <Tooltip
            disabled={activityDate && applicants.length > 0}
            label="Please select a date and at least one applicant to send the message"
          >
            <Button
              text={'Register'}
              variant="filled"
              lefticon={<IconPlane />}
              disabled={!activityDate || applicants.length === 0}
              loading={bulkCallLoading}
              onClick={() => {
                handleSubmit()
              }}
            />
          </Tooltip>
        </Group>
      </Drawer.Body>
    </Drawer>
  )
}

export default BulkCallDrawer
