// Function to convert Base64 to Blob
export const base64ToBlob = ({ base64String, contentType = '' }) => {
  const byteCharacters = atob(base64String)
  const byteArrays = []

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i))
  }

  const byteArray = new Uint8Array(byteArrays)
  return new Blob([byteArray], { type: contentType })
}

export const downloadFileBlob = ({ file, fileName }) => {
  const link = document.createElement('a')
  link.href = URL.createObjectURL(file)
  link.download = fileName
  link.click()
}
