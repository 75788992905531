import { ActionIcon, Badge } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'
import IconClose from 'src/icons/IconClose'

export interface SelectedFilter {
  key: string
  label: string
  value: string
}

export const SelectedFiltersBar = ({
  selectedFilters = [],
  onRemoveFilter,
  onCleanFilters,
}: {
  selectedFilters: SelectedFilter[]
  onRemoveFilter: (filter: SelectedFilter) => void
  onCleanFilters: () => void
}) => {
  return (
    <div className="flex flex-row flex-wrap items-center gap-x-4 gap-y-1">
      {selectedFilters?.map((filter) => {
        return (
          <Badge
            rightSection={
              <ActionIcon
                variant="subtle"
                onClick={() => onRemoveFilter(filter)}
                className="h-6 w-6 bg-doubleNickel-gray-50"
              >
                <IconClose className="fill-none stroke-doubleNickel-gray-400" />
              </ActionIcon>
            }
            key={`${filter.key}-${filter.value}`}
            className="h-7 border border-doubleNickel-gray-200 bg-doubleNickel-gray-50 text-sm font-medium capitalize text-doubleNickel-gray-700"
          >
            {filter.label}
          </Badge>
        )
      })}
      {Object.keys(selectedFilters).length > 0 && (
        <Button
          type="button"
          variant="subtle"
          text="Clear filters"
          onClick={() => onCleanFilters()}
        />
      )}
    </div>
  )
}

export default SelectedFiltersBar
