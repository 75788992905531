import { Radio, Box } from '@mantine/core'

const RadioButton = ({
  template,
  selectedTemplateId,
  setSelectedTemplateId,
}) => {
  return (
    <Box
      key={template.textTemplateId}
      onClick={() => setSelectedTemplateId(template.textTemplateId)}
      className={`mt-4 flex flex-row items-center gap-5 rounded-lg border p-5 ${
        selectedTemplateId === template.textTemplateId
          ? 'border-doubleNickel-brand-500'
          : 'border-doubleNickel-gray-200'
      }`}
    >
      <Radio
        value={template.textTemplateId}
        label={
          <div className="text-md flex flex-col font-semibold text-doubleNickel-gray-700">
            {template.title}
            {selectedTemplateId === template.textTemplateId && (
              <div className="font-normal text-doubleNickel-gray-900">
                {template.content}
              </div>
            )}
          </div>
        }
      />
    </Box>
  )
}

export default RadioButton
