import { formatEnum } from 'src/lib/formatters'

import Card from '../../../../components/DataDisplay/Card/Card'

const MetricList = ({
  selectedMetric,
  metrics = [],
  loading = false,
  onMetricClick,
}) => {
  // Define the desired order of metric names
  const order = ['LEAD', 'APPLICATION', 'QUALIFICATION', 'ONBOARDING']

  // Create a new array that includes all metrics with defaults
  const completeMetrics = order.map((metricName) => {
    // Find the metric in the props, if it exists
    const foundMetric = metrics.find(
      (metric) => metric.name.toUpperCase() === metricName.toUpperCase()
    )

    // Return the found metric or a default object with the name and value 0
    return foundMetric || { name: metricName, value: 0 }
  })

  return (
    <div className="flex h-[5.75rem] flex-row items-center gap-4 px-5 ">
      {completeMetrics.map((metric, index) => (
        <Card
          key={index}
          isSelected={
            selectedMetric?.name?.toUpperCase() == metric.name.toUpperCase()
          }
          title={formatEnum(metric.name)}
          value={metric.value}
          loading={loading}
          onCardClick={() => onMetricClick(metric)}
        />
      ))}
    </div>
  )
}

export default MetricList
