import cloneDeep from 'lodash.clonedeep'

import { ApplicantStage } from 'src/graphql/types/applicants'
import { defaultFilters } from 'src/pages/ApplicantsPage/components/FiltersSiderbar/Filters'

const stringToDate = (dateAsString) => {
  const [month, day, year] = dateAsString.split('/')
  return new Date(year, month - 1, day)
}

export function loadFiltersFromUrl({ jobListings, recruiters }) {
  const searchParams = new URLSearchParams(location.search)
  const filtersCopy = cloneDeep(defaultFilters)

  const jobListingIdFromUrl = searchParams.get('jobListingId')

  for (const filterKey in filtersCopy) {
    const filter = filtersCopy[filterKey]

    if (filter.key === 'jobListings') {
      const positions = jobListings.map((jobListing) => {
        return {
          value: jobListing.jobListingId,
          label: jobListing.title,
          selected:
            jobListingIdFromUrl &&
            jobListingIdFromUrl === jobListing.jobListingId,
        }
      })
      if (jobListingIdFromUrl) {
        filter.selectedValues = [jobListingIdFromUrl]
      }
      filter.options = positions
    }

    if (filter.key === 'recruiters') {
      const employees = recruiters.map((recruiter) => {
        return {
          value: recruiter.employeeId,
          label: `${recruiter.firstName} ${recruiter.lastName}`,
          selected: false,
        }
      })
      filter.options = employees
    }

    if (searchParams.get(filterKey)) {
      if (searchParams.get('searchText')) {
        if (filter.key === 'searchText') {
          filter.searchText = searchParams.get('searchText')
        }
      }

      if (filter.type === 'multi-select') {
        filter.selectedValues = searchParams.get(filterKey).split(',')
        filter.options = filter.options.map((option) => {
          return {
            ...option,
            selected: filter.selectedValues?.includes(option.value),
          }
        })
      }

      if (filter.type === 'step-filter') {
        const stepOptions = searchParams.get(filterKey).split(',')
        const selectedValues = []
        const optionsMap = {}

        stepOptions.forEach((stepOption) => {
          const [stepType, stepStatus] = stepOption.split('-')
          selectedValues.push({ type: stepType, status: stepStatus })

          if (optionsMap[stepType]) {
            optionsMap[stepType].add(stepStatus)
          } else {
            optionsMap[stepType] = new Set()
            optionsMap[stepType].add(stepStatus)
          }
        })

        filter.options = filter.options.map((stepOption) => {
          if (optionsMap[stepOption.value]) {
            stepOption.selected = true
            stepOption.options = stepOption.options.map((option) => {
              return {
                ...option,
                selected: optionsMap[stepOption.value].has(option.value),
              }
            })
          }
          return stepOption
        })
        filter.selectedValues = selectedValues
      }
      if (filter.type === 'applicant-stage-filter') {
        const applicantStageOptions = searchParams.get(filterKey).split(',')
        const selectedValues = []
        const optionsMap = {}

        applicantStageOptions.forEach((applicantStageOption) => {
          const [applicantStage, subOption] = applicantStageOption.split('-')
          if (applicantStage === ApplicantStage.PROSPECT) {
            selectedValues.push({ applicantStage, hiringStage: subOption })
          }
          if (applicantStage === ApplicantStage.DISQUALIFIED) {
            selectedValues.push({
              applicantStage,
              disqualifiedReason: subOption,
            })
          }
          if (applicantStage === ApplicantStage.NOT_INTERESTED) {
            selectedValues.push({
              applicantStage,
              notInterestedReason: subOption,
            })
          }
          if (applicantStage === ApplicantStage.TERMINATED) {
            selectedValues.push({
              applicantStage,
              terminatedReason: subOption,
            })
          }
          if (applicantStage === ApplicantStage.HIRED) {
            selectedValues.push({
              applicantStage,
              terminatedReason: subOption,
            })
          }

          if (optionsMap[applicantStage]) {
            optionsMap[applicantStage].add(subOption)
          } else {
            optionsMap[applicantStage] = new Set()
            optionsMap[applicantStage].add(subOption)
          }
        })

        filter.options = filter.options.map((applicantStageOption) => {
          if (optionsMap[applicantStageOption.value]) {
            applicantStageOption.selected = true
            applicantStageOption.options = applicantStageOption.options.map(
              (option) => {
                return {
                  ...option,
                  selected: optionsMap[applicantStageOption.value].has(
                    option.value
                  ),
                }
              }
            )
          }
          return applicantStageOption
        })
        filter.selectedValues = selectedValues
      }

      if (filter.type === 'range') {
        const values = JSON.parse(searchParams.get(filterKey))
        filter.gte = values.gte
        filter.lte = values.lte
      }

      if (filter.type === 'date-range') {
        const values = JSON.parse(searchParams.get(filterKey))
        if (values.gte) {
          filter.gte = stringToDate(values.gte)
        }
        if (values.lte) {
          filter.lte = stringToDate(values.lte)
        }
      }
    }
  }
  return filtersCopy
}
