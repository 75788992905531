import { Skeleton, Box } from '@mantine/core'

const Card = ({
  title,
  value,
  loading = false,
  onCardClick,
  isSelected = false,
}) => {
  return (
    <Skeleton visible={loading} className="h-full w-[25%] rounded-xl">
      <Box
        onClick={onCardClick}
        className={`flex h-full flex-col justify-center rounded-xl border border border-doubleNickel-gray-200 px-4 shadow-sm hover:bg-doubleNickel-gray-100 ${
          isSelected ? 'bg-doubleNickel-gray-100' : ''
        }`}
      >
        <div className="text-sm font-medium text-doubleNickel-gray-600">
          {title}
        </div>
        <div className="text-2xl font-semibold text-doubleNickel-gray-900">
          {value}
        </div>
      </Box>
    </Skeleton>
  )
}

export default Card
