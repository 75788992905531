import { useEffect, useState } from 'react'

import {
  Group,
  Drawer,
  Divider,
  Box,
  Tooltip,
  Select,
  Textarea,
  Radio,
} from '@mantine/core'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import DateInput from 'src/components/Inputs/DateInput/DateInput'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { BULK_STAGE_UPDATE } from 'src/graphql/applicants.graphql'
import {
  ApplicantStage,
  DisqualifiedReason,
  HiringStage,
  NotInterestedReason,
  TerminatedReason,
} from 'src/graphql/types/applicants'
import IconPlane from 'src/icons/IconPlane'
import { formatSnakeValue } from 'src/lib/formatters'

import ApplicantList from './components/ApplicantList'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const BulkStatusDrawer = ({
  applicants,
  setApplicants,
  isOpen,
  refetchQueries = [],
  onClose,
}) => {
  const [applicantStageDate, setApplicantStageDate] = useState(new Date())
  const [applicantStage, setApplicantStage] = useState(null)
  const [hiringStage, setHiringStage] = useState(null)
  const [reason, setReason] = useState(null)
  const [content, setContent] = useState('')
  const [disqualifiedOrEligible, setDisqualifiedOrEligible] = useState(false)
  const [showingApplicants, setShowingApplicants] = useState(false)
  const [bulkStageResult, setBulkStageResult] = useState(null)

  // MUTATIONS:
  const [bulkStageUpdate, { data: bulkStageData, loading: bulkStageLoading }] =
    useMutation(BULK_STAGE_UPDATE, {
      refetchQueries: refetchQueries,
      onCompleted: () => {
        toast('Applicants were updated successfully', 'success')
      },
      onError,
    })

  useEffect(() => {
    if (bulkStageData) {
      setBulkStageResult(bulkStageData.bulkStageUpdate.applicants)
    }
  }, [bulkStageData])

  const handleClose = () => {
    setBulkStageResult(null)
    onClose()
  }

  const handleSubmit = () => {
    setShowingApplicants(true)
    bulkStageUpdate({
      variables: {
        applicants: applicants.map((applicant) => applicant.applicantId),
        input: {
          applicantStage,
          applicantStageDate,
          ...(applicantStage === ApplicantStage.PROSPECT && { hiringStage }),
          ...(applicantStage === ApplicantStage.DISQUALIFIED && {
            disqualifiedReason: reason,
          }),
          ...(applicantStage === ApplicantStage.NOT_INTERESTED && {
            notInterestedReason: reason,
          }),
          ...(applicantStage === ApplicantStage.TERMINATED && {
            eligibleForRehire: disqualifiedOrEligible,
            terminatedReason: reason,
          }),
          ...(applicantStage === ApplicantStage.DISQUALIFIED && {
            isPermanentlyDisqualified: disqualifiedOrEligible,
            disqualifiedReason: reason,
          }),
          content,
        },
      },
    })
  }

  const configureLabel = () => {
    switch (applicantStage) {
      case ApplicantStage.TERMINATED:
        return 'Termination Reason'
      case ApplicantStage.NOT_INTERESTED:
        return 'Not Interested Reason'
      case ApplicantStage.DISQUALIFIED:
        return 'Disqualification Reason'
      default:
        return 'Select'
    }
  }

  const configureData = () => {
    switch (applicantStage) {
      case ApplicantStage.TERMINATED:
        return Object.keys(TerminatedReason).map((key) => ({
          value: key,
          label: formatSnakeValue(key),
        }))
      case ApplicantStage.NOT_INTERESTED:
        return Object.keys(NotInterestedReason).map((key) => ({
          value: key,
          label: formatSnakeValue(key),
        }))
      case ApplicantStage.DISQUALIFIED:
        return Object.keys(DisqualifiedReason).map((key) => ({
          value: key,
          label: formatSnakeValue(key),
        }))
      default:
        return []
    }
  }

  const isDisabled = () => {
    if (applicants.length === 0 || !applicantStage || !applicantStageDate) {
      return true
    }

    if (applicantStage === ApplicantStage.PROSPECT && !hiringStage) {
      return true
    }

    if (
      (applicantStage === ApplicantStage.DISQUALIFIED ||
        applicantStage === ApplicantStage.NOT_INTERESTED ||
        applicantStage === ApplicantStage.TERMINATED) &&
      !reason
    ) {
      return true
    }

    if (
      (applicantStage === ApplicantStage.TERMINATED ||
        applicantStage === ApplicantStage.DISQUALIFIED) &&
      disqualifiedOrEligible === null
    ) {
      return true
    }

    return false
  }

  return (
    <Drawer
      opened={isOpen}
      onClose={handleClose}
      closeOnClickOutside={!bulkStageLoading}
      closeOnEscape={!bulkStageLoading}
      title={'Change Applicant Stage'}
      classNames={{
        title: 'text-lg font-bold',
        content: 'flex flex-col h-full',
        body: 'flex flex-col justify-between flex-1 px-2 overflow-hidden',
      }}
      position="right"
    >
      <Drawer.Body>
        <div className="flex h-[93%] flex-col gap-5 overflow-auto">
          <div className="text-md flex flex-row items-center gap-4 font-medium text-doubleNickel-gray-900">
            Changing stage for: {applicants.length}{' '}
            {applicants.length === 1 ? 'applicant' : 'applicants'}
            <Box
              className="cursor-pointer text-sm font-semibold text-doubleNickel-brand-500 hover:underline"
              onClick={() => {
                setShowingApplicants(!showingApplicants)
              }}
            >
              {showingApplicants ? 'Hide all' : 'Show all'}
            </Box>
          </div>
          {showingApplicants && (
            <ApplicantList
              applicants={applicants}
              bulkSmsLoading={bulkStageLoading}
              bulkSmsResult={bulkStageResult}
              setApplicants={setApplicants}
            />
          )}
          <Divider />
          <div className="flex flex-col gap-4">
            <div className="text-md font-medium text-doubleNickel-gray-900">
              Select the stage you want to apply:
            </div>

            <div className="grid grid-cols-2 gap-4">
              <Select
                label="Stage"
                value={applicantStage}
                onChange={setApplicantStage}
                data={Object.keys(ApplicantStage).map((key) => ({
                  value: key,
                  label: formatSnakeValue(key),
                }))}
                required
                placeholder="Select a stage"
              />
              {applicantStage === ApplicantStage.PROSPECT && (
                <Select
                  label="Hiring Stage"
                  data={Object.keys(HiringStage).map((key) => ({
                    value: key,
                    label: formatSnakeValue(key),
                  }))}
                  required
                  value={hiringStage}
                  onChange={setHiringStage}
                  placeholder="Select"
                />
              )}
              {applicantStage !== ApplicantStage.PROSPECT &&
                applicantStage !== ApplicantStage.HIRED && (
                  <Select
                    key={applicantStage}
                    label={configureLabel()}
                    data={configureData()}
                    value={reason}
                    required
                    onChange={setReason}
                    placeholder="Select"
                  />
                )}
              <DateInput
                label="Date"
                required
                value={applicantStageDate}
                onChange={setApplicantStageDate}
              />
              {(applicantStage === ApplicantStage.TERMINATED ||
                applicantStage === ApplicantStage.DISQUALIFIED) && (
                <div className="col-span-2">
                  <Radio.Group
                    required
                    label={
                      applicantStage === ApplicantStage.TERMINATED
                        ? 'Is this applicant eligible for rehire?'
                        : 'Is this applicant permanently disqualified?'
                    }
                    value={disqualifiedOrEligible.toString()}
                    classNames={{
                      root: 'flex flex-col gap-4',
                      label: 'text-md text-doubleNickel-gray-900',
                    }}
                  >
                    <div className="grid grid-cols-2 gap-4">
                      <Box
                        onClick={() => setDisqualifiedOrEligible(true)}
                        className={`shadow-xs rounded-lg border px-4 py-3 hover:border-doubleNickel-brand-500 ${
                          disqualifiedOrEligible
                            ? 'border-doubleNickel-brand-500'
                            : 'border-doubleNickel-gray-300'
                        }`}
                      >
                        <Radio value="true" label="Yes" />
                      </Box>
                      <Box
                        onClick={() => setDisqualifiedOrEligible(false)}
                        className={`shadow-xs rounded-lg  border px-4 py-3 hover:border-doubleNickel-brand-500 ${
                          !disqualifiedOrEligible
                            ? 'border-doubleNickel-brand-500'
                            : 'border-doubleNickel-gray-300'
                        }`}
                      >
                        <Radio value="false" label="No" />
                      </Box>
                    </div>
                  </Radio.Group>
                </div>
              )}
              <div className="col-span-2">
                <Textarea
                  label="Notes"
                  value={content}
                  placeholder="Additional details or comments (optional)"
                  onChange={(event) => setContent(event.currentTarget.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <Group className="absolute bottom-0 left-0 right-0 mt-auto grid grid-cols-2 gap-4 border-t bg-white p-4">
          <Button
            text="Cancel"
            variant="outline"
            onClick={handleClose}
            disabled={bulkStageLoading}
          />
          <Tooltip
            disabled={!isDisabled()}
            label="Please select at least one applicant and complete all required fields"
          >
            <Button
              text={'Update'}
              variant="filled"
              lefticon={<IconPlane />}
              disabled={isDisabled()}
              loading={bulkStageLoading}
              onClick={() => {
                handleSubmit()
              }}
            />
          </Tooltip>
        </Group>
      </Drawer.Body>
    </Drawer>
  )
}

export default BulkStatusDrawer
