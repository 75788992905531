import { useState } from 'react'

import { Checkbox, Accordion } from '@mantine/core'

interface SelectedSteps {
  [key: string]: string[]
}

export const StepFilter = ({ stepOptions, setStepOptions }) => {
  const initializeSelectedSteps = (stepOptions) => {
    const selectedSteps = {}
    stepOptions.forEach((typeOption) => {
      if (typeOption.selected) {
        selectedSteps[typeOption.value] = typeOption.options
          .filter((statusOption) => statusOption.selected)
          .map((statusOption) => statusOption.value)
      }
    })
    return selectedSteps
  }

  const [selectedSteps, setSelectedSteps] = useState<SelectedSteps>(
    initializeSelectedSteps(stepOptions)
  )

  const updateStepOptions = (newSelectedSteps: SelectedSteps) => {
    const updatedStepOptions = stepOptions.map((typeOption) => {
      const isSelectedType = typeOption.value in newSelectedSteps
      const updatedTypeOption = {
        ...typeOption,
        selected: isSelectedType,
        options: typeOption.options.map((statusOption) => {
          const isSelectedStatus =
            isSelectedType &&
            newSelectedSteps[typeOption.value].includes(statusOption.value)
          return {
            ...statusOption,
            selected: isSelectedStatus,
          }
        }),
      }
      return updatedTypeOption
    })

    setStepOptions(updatedStepOptions)
  }

  const handleTypeChange = (type) => {
    setSelectedSteps((prevSelected) => {
      const newSelected = { ...prevSelected }

      if (newSelected[type]) {
        // If the type is already selected, toggle it off and remove its statuses
        delete newSelected[type]
      } else {
        // If the type is not selected, add it with an empty array for statuses
        newSelected[type] = []
      }

      updateStepOptions(newSelected)
      return newSelected
    })
  }

  const handleStatusChange = (type, status, checked) => {
    setSelectedSteps((prevSelected) => {
      const selectedStatuses = prevSelected[type] || []
      const newSelectedStatuses = checked
        ? [...selectedStatuses, status]
        : selectedStatuses.filter((s) => s !== status)

      const newSelected = { ...prevSelected, [type]: newSelectedStatuses }

      updateStepOptions(newSelected)
      return newSelected
    })
  }

  return (
    <Accordion.Item value="Steps" className={`pb-4 pt-4`}>
      <Accordion.Control
        classNames={{
          control: 'p-0',
          label: 'text-lg font-bold text-doubleNickel-gray-900',
        }}
      >
        Steps
      </Accordion.Control>
      <Accordion.Panel classNames={{ content: 'px-0' }}>
        <div className="flex flex-col gap-5">
          {stepOptions.map((typeOption) => (
            <div key={typeOption.value} className="gap-5">
              <Checkbox
                label={typeOption.label}
                checked={typeOption.selected}
                onChange={() => handleTypeChange(typeOption.value)}
              />
              {typeOption.value in selectedSteps && (
                <div className="flex flex-col gap-5 px-5 py-5">
                  {typeOption.options.map((statusOption) => (
                    <Checkbox
                      key={statusOption.value}
                      label={statusOption.label}
                      checked={statusOption.selected}
                      onChange={(e) =>
                        handleStatusChange(
                          typeOption.value,
                          statusOption.value,
                          e.currentTarget.checked
                        )
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
