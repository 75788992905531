import { RangeSlider, Accordion } from '@mantine/core'
import { useCallback } from 'react'

export interface RangeFilterProps {
  title: string
  min: number
  max: number
  step: number
  minRange: number
  value: [lte: number | null, gte: number | null]
  setValue: ([lte, gte]: [lte: number, gte: number]) => void
}

const RangeFilter = ({
  title,
  min = 0,
  max = 30,
  step = 1,
  minRange = 1,
  value,
  setValue,
}: RangeFilterProps) => {
  const marks = [
    { value: min, label: '' + min },
    { value: max, label: '' + max + '+' },
  ]

  const handleSliderChange = useCallback(
    (newValue: [number, number]) => {
      if (newValue[0] !== null && newValue[1] !== null) {
        setValue([newValue[0], newValue[1]])
      }
    },
    [setValue]
  )

  return (
    <Accordion.Item value={title} className="py-4">
      <Accordion.Control
        classNames={{
          control: 'p-0',
          label: 'text-lg font-bold text-doubleNickel-gray-900',
        }}
      >
        {title}
      </Accordion.Control>
      <Accordion.Panel classNames={{ content: 'px-0' }}>
        <RangeSlider
          min={min}
          max={max}
          step={step}
          minRange={minRange}
          marks={marks}
          thumbSize={24}
          value={[value[0] ?? min, value[1] ?? max]} // Ensure no null values
          classNames={{
            label:
              ' border border-doubleNickel-gray-200 rounded-lg bg-doubleNickel-white text-doubleNickel-gray-700 shadow-lg pb-1 text-sm font-semibold',
            root: 'pb-8 pt-2 px-6',
            thumb: 'border-2 border shadow-md',
            markLabel: 'pt-1',
          }}
          onChange={handleSliderChange}
        />
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export default RangeFilter
