import { ActionIcon, Loader, Tooltip } from '@mantine/core'

import IconCheckmarkCircle from 'src/icons/IconCheckmarkCircle'
import IconClose from 'src/icons/IconClose'
import IconCloseCircle from 'src/icons/IconCloseCircle'

const ApplicantList = ({
  applicants,
  bulkSmsLoading,
  bulkSmsResult,
  setApplicants,
}) => {
  const removeApplicant = (applicantId) => {
    setApplicants(
      applicants.filter((applicant) => applicant.applicantId !== applicantId)
    )
  }

  const renderApplicantIcon = (applicant) => {
    if (bulkSmsLoading) {
      return <Loader size={'xs'} color="gray" />
    } else if (bulkSmsResult) {
      const result = bulkSmsResult.find(
        (result) => result.applicantId === applicant.applicantId
      )
      if (result?.success) {
        return (
          <IconCheckmarkCircle className="h-4 fill-none stroke-doubleNickel-success-500" />
        )
      } else {
        return (
          <Tooltip label={result.errorMsg}>
            <div>
              <IconCloseCircle className="h-4 fill-none stroke-doubleNickel-error-500" />
            </div>
          </Tooltip>
        )
      }
    }
  }

  return (
    <div className="flex flex-col gap-3 rounded-lg border border-doubleNickel-gray-200 p-6">
      {applicants.map((applicant) => (
        <div
          key={applicant.applicantId}
          className="flex flex-row items-center justify-between text-sm text-doubleNickel-gray-900"
        >
          {applicant.name}

          <div className="flex flex-row items-center gap-2">
            {renderApplicantIcon(applicant)}

            <ActionIcon
              variant="subtle"
              disabled={bulkSmsLoading}
              onClick={() => {
                if (bulkSmsLoading) return
                removeApplicant(applicant.applicantId)
              }}
            >
              <IconClose className="fill-none stroke-doubleNickel-gray-500" />
            </ActionIcon>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ApplicantList
